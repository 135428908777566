import http from '../../../../../libs/http'
import axios from 'axios'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONTROL_ENVIO
export default class ControlEnvioService {
  getInfoGestion (payload) {
    return http.get(`${baseUrl}/gestion/get-info-control/`, {
      params: { ...payload },
      headers: {
        loading: true
      }
    })
  }

  getInfoMasivaGestion (payload) {
    return http.get(`${baseUrl}/gestion/get-info-masiva-control`, {
      params: { ...payload },
      headers: {
        loading: true
      }
    })
  }

  getTransportista = () => {
    return http.get(`${baseUrl}/transportista/`, { headers: { loading: true } })
  }

  validateNumGuia = (numGuia) => {
    return http.get(`${baseUrl}/gestion/getGuide/${numGuia}`, { headers: { loading: true } })
  }

  getPaginate (payload) {
    return http.get(`${baseUrl}/listado/get-list-envios`, {
      params: { ...payload },
      headers: {
        loading: true
      }
    })
  }

  getPaginatePendientes (payload) {
    return http.get(`${baseUrl}/listado/get-list-envios-pendientes`, {
      params: { ...payload },
      headers: {
        loading: true
      }
    })
  }

  getAllBodegas () {
    return http.get(`${baseUrl}/listado/get-bodegas`, {
      headers: {
        loading: true
      }
    })
  }

  getAllUsers () {
    return http.get(`${baseUrl}/listado/get-users`, {
      headers: {
        loading: true
      }
    })
  }

  getValidateUser (payload) {
    return http.get(`${baseUrl}/listado/get-user-whscode`, {
      params: { ...payload },
      headers: {
        loading: true
      }
    })
  }

  exportDataExcel = (params) => {
    return new Promise((resolve) => {
      const url = `${baseUrl}/listado/export-excel`
      const res = axios.getUri({ url, params })
      resolve(res)
    })
  }

   verOficioTransferencia = ({ idEnvio, tipoOficio }) => {
    const url = `${baseUrl}/listado/oficio-transferencia/${idEnvio}/${tipoOficio}`
    const res = axios.getUri({ url })
    window.open(res, '_blank')
  }

  getInfoModal (payload) {
    return http.get(`${baseUrl}/listado/get-info-envio/${payload}`, {
      headers: {
        loading: true
      }
    })
  }

  getInfoModalEnvioPendiente ({ DocNum, typeDoc }) {
    return http.get(`${baseUrl}/listado/get-info-envio-pendiente/${DocNum}/${typeDoc}`, {
      headers: {
        loading: true
      }
    })
  }

  getBatchNumbers ({ ItemCode, codBodOrigen }) {
    return http.get(`${baseUrl}/gestion/get-batch-numbers/${ItemCode}/${codBodOrigen}`, {
      headers: {
        loading: true
      }
    })
  }

  infoEnvio (id) {
    const url = `${baseUrl}/listado/info-envio/${id}`
    const res = axios.getUri({ url })
    window.open(res, '_blank')
  }

  createControlEnvio (payload) {
    return http.post(`${baseUrl}/gestion/post-control-envio/`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
