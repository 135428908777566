<template>
  <section class="w-full bg-white">
<!--    <div class="flex justify-start bg-white p-2 header-title divheader">-->
<!--      <div class="grid-cols-2 text-[#354357] font-bold my-auto" >-->
<!--        <h2 class="text-lg">-->
<!--          <i class="pi pi-list" style="font-size: 1rem"></i>-->
<!--          Listado de Envíos-->
<!--        </h2>-->
<!--      </div>-->
<!--      <div>-->
<!--        <Button v-if="$can('pharmasan.logistica.control-envio.access')" icon="pi pi-send" label="Crear Envio" class="btn-create" @click="$router.push({name:'pharmasan.compras.logistica.control-envios'})" />-->
<!--      </div>-->
<!--    </div>-->
    <div class="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 pl-4 pr-4 pt-4 pb-2 gap-4 lg:mb-0">
      <div class="field">
        <label for="icon">Fecha inicial</label>
        <input v-model="filters.fechaInicial"
               class="p-button-secondary w-full border p-2 rounded-md mb-1 mt-1 flex-items-center p-inputtext-xs"
               type="date">
      </div>
      <div class="field">
        <label for="icon">Fecha final</label>
        <input v-model="filters.fechaFinal"
               class="p-button-secondary w-full border p-2 rounded-md mb-1 mt-1 flex-items-center p-inputtext-xs"
               type="date">
      </div>
      <div class="field">
        <label>Bodega Origen</label>
        <Dropdown
          optionLabel="bodega"
          optionValue="WhsCode"
          v-model="filters.WhsCode"
          :options="bodegas"
          panelClass="text-xxs"
          @click="$h.xxsInput"
          placeholder="Seleccione una bodega origen"
          class="rounded-md border-gray-300 w-full p-inputtext-xxs mt-1"
          :filter="true"
        />
      </div>
      <div class="field">
        <label>Bodega Destino</label>
        <Dropdown
          optionLabel="bodega"
          optionValue="WhsCode"
          v-model="filters.ToWareHouseCode"
          :options="bodegas"
          panelClass="text-xxs"
          @click="$h.xxsInput"
          placeholder="Seleccione una bodega destino"
          class="rounded-md border-gray-300 w-full p-inputtext-xxs mt-1"
          :filter="true"
        />
      </div>
      <div class="field">
        <label>Usuario</label>
        <Dropdown
          optionLabel="nameUser_responsable"
          optionValue="created_by"
          @click="$h.xxsInput"
          :options="users"
          v-model="filters.user_id"
          panelClass="text-xxs"
          placeholder="Seleccione un usuario"
          class="rounded-md border-gray-300 w-full p-inputtext-xxs mt-1"
          :filter="true"
        />
      </div>
      <div class="field">
        <label>Estado</label>
        <Dropdown
          optionLabel="name"
          optionValue="id"
          :options="estados"
          v-model="filters.estado"
          placeholder="Seleccione un estado"
          panelClass="text-xxs"
          class="rounded-md border-gray-300 w-full p-inputtext-xxs mt-1"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 pl-4 gap-4 pr-4 pb-4 lg:mb-0">
      <div class="field grid">
        <label>Número de envío</label>
        <Chips
          id="DocNums"
          :allowDuplicate="false"
          v-model="filters.DocNums"
          separator=","
          inputClass="p-inputtext-xs"
          class="text-xs p-inputtext-xs w-full"
          placeholder="Envíos separados por ,"
        />
      </div>
    </div>
    <div class="flex justify-end p-4 gap-2">
      <Button label="Exportar Excel" icon="pi pi-file-excel" class="p-button-xs p-button-success" @click="excelExport"/>
      <Button label="Limpiar" icon="pi pi-times" class="p-button-xs p-button-secondary" @click="clearFilters"/>
      <Button label="Buscar" icon="pi pi-search" class="p-button-xs" @click="getPaginateControl"/>
    </div>
  </section>
  <div class="pt-6 bg-white p-4">
    <DataTable
      :value="envios"
      class="p-datatable-sm text-xs"
      showGridlines
      dataKey="CardCode"
      responsiveLayout="scroll"
      ref="tableList"
      removableSort
    >
      <template #empty>
        No se encontraron envíos registrados.
      </template>
      <Column field="created_at" header="Fecha" headerStyle="text-align: center;justify-content: center;"
              style="min-width:10rem" bodyStyle="text-align: center">
        <template #body="{data}">
          <p class="text-xs">
            {{ $h.formatDate(data.created_at) }}
          </p>
        </template>
      </Column>
      <Column field="doc_num" header="N° Transferencia" headerStyle="text-align: center;justify-content: center;"
              style="min-width:0.5rem" bodyStyle="text-align: center">
        <template #body="{data}">
          <Button :label="`${data.doc_num}`"
                  class="p-button-link text-black"
                  style="font-size: 0.75rem"
                  @click="openModalDetail(data.doc_num)"
          />
        </template>
      </Column>
      <Column field="WhsCode" header="Bodega Origen" headerStyle="text-align: center;" style="width:15rem" bodyStyle="text-align: center"/>
      <Column field="ToWareHouseCode" header="Bodega Destino" headerStyle="text-align: center;" style="width:15rem" bodyStyle="text-align: center"/>
      <Column field="num_guia" header="Número de guia" headerStyle="text-align: center;justify-content: center;"
              style="min-width:10rem" bodyStyle="text-align: center">
        <template #body="{data}">
          <Button :label="`${data.num_guia}`"
                  v-if="data.url"
                  class="p-button-link text-black"
                  style="font-size: 0.75rem"
                  @click="openGuia(data)"
          />
          <p class="text-xs" v-else>
            {{ data.num_guia }}
          </p>
        </template>
      </Column>
      <Column field="nameUser_responsable" header="Usuario" headerStyle="text-align: center;justify-content: center;"
              style="min-width:10rem" bodyStyle="text-align: center">
        <template #body="{data}">
          <p class="text-xs">
            {{ data.nameUser_responsable }}
          </p>
        </template>
      </Column>
      <Column field="estadoRecep" header="Estado" headerStyle="text-align: center;justify-content: center;"
              style="min-width:7rem" bodyStyle="text-align: center">
        <template #body="{data}">
          <p class="text-xs">
            <Badge v-show="data.NombreEstado === 'EN TRANSITO'" :value="data.NombreEstado" class="entransito"/>
            <Badge v-show="data.NombreEstado === 'POR RECEPCIONAR'" :value="data.NombreEstado" class="pendiente"/>
            <Badge v-show="data.NombreEstado === 'EN STOCK'" :value="data.NombreEstado" class="enstock"/>
            <Badge v-show="data.NombreEstado === 'EN ESCALA'" :value="data.NombreEstado" class="enescala"/>
          </p>
        </template>
      </Column>
      <Column field="" header="Acciones" style="min-width:5rem" bodyStyle="text-align: center">
        <template #body="{data}">
          <Button icon="pi pi-download" v-tooltip.bottom="'Descargar oficio firmado'"
                  class="p-button-rounded p-button-text p-button-plain" @click="dowloadPdf(data.id)"/>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="params.offset"
      :rows="params.limit"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[10,20,30,50]"
      :pageLinkSize="5"
      @page="onPage($event)"
    />
  </div>
  <infoModal ref="openModalDet"/>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue'
import ControlEnvioService from '@/apps/pharmasan/compras/logistica/services/control.service'
import { menuCollapse } from '@/utils/collapseMenu'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

export default {
  name: 'realizados',
  components: {
    infoModal: defineAsyncComponent(() =>
      import('./components/infoModal.vue')
    )
  },
  setup () {
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const totalRecords = ref(0)
    const pageLinkSize = ref(5)
    const openModalDet = ref()
    const bodegas = ref([])
    const users = ref([])
    const _ControlEnvioService = new ControlEnvioService()
    const envios = ref([])
    const estados = ref([{ id: 0, name: 'EN TRANSITO' }, { id: 1, name: 'POR RECEPCIONAR' }, {
      id: 2,
      name: 'EN STOCK'
    }, { id: 3, name: 'EN ESCALA' }])
    const filters = reactive({
      fechaFinal: null,
      fechaInicial: null,
      WhsCode: null,
      ToWareHouseCode: null,
      user_id: null,
      DocNums: []
    })
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        WhsCode: filters.WhsCode,
        ToWareHouseCode: filters.ToWareHouseCode,
        user_id: filters.user_id,
        estado: filters.estado,
        DocNums: filters.DocNums
      }
    })
    const getBodegas = async () => {
      await _ControlEnvioService.getAllBodegas().then(({ data }) => {
        bodegas.value = data
      })
    }
    const getUsers = async () => {
      await _ControlEnvioService.getAllUsers().then(({ data }) => {
        users.value = data
      })
    }
    const openGuia = ({ url }) => {
      window.open(url, '_blank')
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getPaginateControl(params.value)
    }
    const clearFilters = () => {
      filters.WhsCode = null
      filters.ToWareHouseCode = null
      filters.user_id = null
      filters.estado = null
      filters.DocNums = []
      getPaginateControl()
    }
    const excelExport = async () => {
      await getPaginateControl()
      await _ControlEnvioService.exportDataExcel(params.value).then((url) => {
        window.open(url, '_blank')
      })
    }
    const dowloadPdf = async (id) => {
      await _ControlEnvioService.verOficioTransferencia({ idEnvio: id, tipoOficio: '1' })
    }
    const openModalDetail = async (idTransferencia) => {
      await _ControlEnvioService.getInfoModal(idTransferencia).then(({ data }) => {
        openModalDet.value.openModal(data)
      })
    }
    const getPaginateControl = async () => {
      if ([undefined, null, ''].includes(filters.fechaInicial) || [undefined, null, ''].includes(filters.fechaFinal)) {
        await Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Por favor seleccione una fecha inicial y una fecha final.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      await _ControlEnvioService.getPaginate(params.value).then((response) => {
        envios.value = response.data.data
        totalRecords.value = response.data.rows[0].countRows
      })
    }
    onMounted(() => {
      menuCollapse()
      const now = dayjs()
      const lastMonth = now.subtract(1, 'month')
      filters.fechaInicial = lastMonth.format('YYYY-MM-DD')
      filters.fechaFinal = now.format('YYYY-MM-DD')
      getPaginateControl()
      getBodegas()
      getUsers()
    })
    return {
      filters,
      envios,
      getPaginateControl,
      offset,
      page,
      limit,
      totalRecords,
      pageLinkSize,
      params,
      onPage,
      clearFilters,
      bodegas,
      users,
      getUsers,
      estados,
      excelExport,
      dowloadPdf,
      openModalDetail,
      openModalDet,
      openGuia
    }
  }
}
</script>
<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}
.header-title {
  background-color: #1c3faa;
  justify-content: space-between;
  color: white;
  border-radius: 5px;
}
.entransito {
  background-color: #6A6A6A ;
  color: #fff;
  font-size: 10px;
  width: 120px;
}

.enstock {
  background-color: #43B62D;
  color: #fff;
  font-size: 10px;
  width: 120px;
}

.enescala {
  background-color: #318221 ;
  color: #fff;
  font-size: 10px;
  width: 120px;
}

.pendiente {
  background-color: #14999A;
  color: #fff;
  font-size: 10px;
  width: 120px;
}
.btn-create {
  color: #1C3FAA;
  background: #fff;
}
.btn-create:hover {
  color: #1C3FAA;
  background: #fff;
}
.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}
</style>
